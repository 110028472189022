import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./footer";
import { getBaseUrl } from "../utils";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './aboutus.css';


function PrivacyPolicymobilesecurity() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1200, // Customize the duration as needed
    });
  }, []);
  return (
    <Fragment>
        <>
          <Navbar />
         
          <>
  <section
    className="d-flex align-items-center page-hero  inner-page-hero "
    id="page-hero"
    style={{ backgroundImage: 'url("assets/assets/images/solutionbanner.png")' }}
  >
    <div
      className="overlay-photo-image-bg parallax"
      data-bg-img="https://www.reptileindia.co.in/laravel_website/public/assets/images/hero/inner-page-hero.jpg"
      data-bg-opacity={1}
    />
    <div className="overlay-color" data-bg-opacity=".75" />
    <div className="container">
      <div className="hero-text-area centerd">
        <h1 className="hero-title  wow fadeInUp" data-wow-delay=".2s">
          Privacy Policy AntiTheft
        </h1>
        <nav aria-label="breadcrumb ">
          <ul className="breadcrumb wow fadeInUp" data-wow-delay=".6s">
            <li className="breadcrumb-item">
              <a className="breadcrumb-link" href="#0">
                <i className="bi bi-house icon " />
                home
              </a>
            </li>
            <li className="breadcrumb-item active">Privacy Policy AntiTheft</li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
  
  <section className="about mega-section" id="about">
    {/* <div className="container">
      <div className="content-block" style={{ marginBottom: 0 }}>
        <div className="row">
          <div
            className="col-12 col-lg-12 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp "
            data-wow-delay="0.6s"
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2
                    style={{ fontSize: 45 }}
                    className="title wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <span className="hollow-text"> Privacy Policy</span>
                  </h2>
                </div>
              </div>
              <p className="about-text">
                This Privacy Policy governs the manner in which
                reptileindia.co.in collects, uses, maintains and discloses
                information collected from users (each, a "User") of the
                http://www.reptileindia.co.in website ("Site"). This privacy
                policy applies to the Site and offered by
                www.reptileindia.co.in, which is owned and operated by Carecone
                Technologies Private Limited.
              </p>
              <h6> 1. PERSONAL IDENTIFICATION INFORMATION</h6>
              <p className="about-text">
                {" "}
                We may collect personal identification information from Users in
                a variety of ways, including, but not limited to, when Users
                visit our site, register on the site, place an order, and in
                connection with other activities, services, features or
                resources we make available on our Site. Users may be asked for,
                as appropriate, name, email address, mailing address, phone
                number. Users may, however, visit our Site anonymously. We will
                collect personal identification information from Users only if
                they voluntarily submit such information to us. Users can always
                refuse to supply personally identification information, except
                that it may prevent them from engaging in certain Site related
                activities.{" "}
              </p>
              <p className="about-text">Name</p>
              <p className="about-text">E-mail.</p>
              <p className="about-text">
                User's GPS Location (Conditional) for attendance/tasks
              </p>
              <p className="about-text">
                Background Location (Conditional) for automated expense
              </p>
              <p className="about-text">
                Device Model and network connectivity.
              </p>
              <p className="about-text">
                Camera for selfie attendance and while filling the pictures
                related filed task forms
              </p>
              <p className="about-text">
                Audio for field data collection in audio field.
              </p>
              <p className="about-text" style={{ fontWeight: "bold" }}>
                <a href="https://policies.google.com/privacy" target="_blank">
                  Google Play Services
                </a>
              </p>
              <h6>2. NON-PERSONAL IDENTIFICATION INFORMATION</h6>
              <p className="about-text">
                {" "}
                We may collect non-personal identification information about
                Users whenever they interact with our Site. Non-personal
                identification information may include the browser name, the
                type of computer and technical information about Users means of
                connection to our Site, such as the operating system and the
                Internet service providers' utilized and other similar
                information.
              </p>
              <h6>3. WEB BROWSER COOKIES</h6>
              <p className="about-text">
                Currently we do not use cookies. Our Site may use "cookies" to
                enhance User experience. User's web browser places cookies on
                their hard drive for record-keeping purposes and sometimes to
                track information about them. User may choose to set their web
                browser to refuse cookies, or to alert you when cookies are
                being sent. If they do so, note that some parts of the Site may
                not function properly.
              </p>
              <h6>4. HOW WE USE COLLECTED INFORMATION</h6>
              <p className="about-text">
                Reptileindia.co.in may collect and use Users personal
                information for the following purposes:
              </p>
              <p className="about-text">
                • To improve customer service - Information you provide helps us
                respond to your customer service requests and support needs more
                efficiently.
              </p>
              <p className="about-text">
                • To personalize user experience - We may use information in the
                aggregate to understand how our Users as a group use the
                services and resources provided on our Site.
              </p>
              <p className="about-text">
                • To improve our Site - We may use feedback you provide to
                improve our products and services.
              </p>
              <p className="about-text">
                • To process payments - We may use the information Users provide
                about themselves when placing an order only to provide service
                to that order. We do not share this information with outside
                parties except to the extent necessary to provide the service.
              </p>
              <p className="about-text">
                • To run a promotion, contest, survey or other Site feature - To
                send Users information they agreed to receive about topics we
                think will be of interest to them.
              </p>
              <p className="about-text">
                • To send periodic emails - We may use the email address to send
                User information and updates pertaining to their order. It may
                also be used to respond to their inquiries, questions, and/or
                other requests. If User decides to opt-in to our mailing list,
                they will receive emails that may include company news, updates,
                related product or service information, etc. If at any time the
                User would like to unsubscribe from receiving future emails, we
                include detailed unsubscribe instructions at the bottom of each
                email or User may contact us via our Site.
              </p>
              <p className="about-text" style={{ fontWeight: "bold" }}>
                <a
                  href="https://www.whatsapp.com/legal/business-policy/"
                  target="_blank"
                >
                  Whatsapp Business Policies
                </a>
              </p>
              <h6>5. HOW WE PROTECT YOUR INFORMATION</h6>
              <p className="about-text">
                We adopt appropriate data collection, storage and processing
                practices and security measures to protect against unauthorized
                access, alteration, disclosure or destruction of your personal
                information, username, password, transaction information and
                data stored on our Site. For any such kind of queries you can
                also connect with us at info@reptileindia.co.in . Sensitive and
                private data exchange between the Site and its Users happens
                over a SSL secured communication channel and is encrypted and
                protected with digital signatures.
              </p>
              <h6>6. SHARING YOUR PERSONAL INFORMATION</h6>
              <p className="about-text">
                We may use third party service providers to help us operate our
                business and the Site or administer activities on our behalf,
                such as sending out newsletters or surveys. We may share your
                information with these third parties for those limited purposes
                provided that you have given us your permission.
              </p>
              <h6>7. GOOGLE ADSENSE</h6>
              <p className="about-text">
                Some of the ads may be served by Google. Google's use of the
                DART cookie enables it to serve ads to Users based on their
                visit to our Site and other sites on the Internet. DART uses
                "non personally identifiable information" and does NOT track
                personal information about you, such as your name, email
                address, physical address, etc. You may opt out of the use of
                the DART cookie by visiting the Google ad and content network
                privacy policy at http://www.google.com/privacy_ads.html{" "}
              </p>
              <h6>
                8. COMPLIANCE WITH CHILDREN'S ONLINE PRIVACY PROTECTION ACT
              </h6>
              <p className="about-text">
                Protecting the privacy of the very young is especially
                important. For that reason, we never collect or maintain
                information at our Site from those we actually know are under
                18, and no part of our website is structured to attract anyone
                under 18.
              </p>
              <h6>9. CHANGES TO THIS PRIVACY POLICY</h6>
              <p className="about-text">
                Reptileindia.co.in have the discretion to update this privacy
                policy at any time. When we do, we will send you an email. We
                encourage Users to frequently check this page for any changes to
                stay informed about how we are helping to protect the personal
                information we collect. You acknowledge and agree that it is
                your responsibility to review this privacy policy periodically
                and become aware of modifications.
              </p>
              <h6>10. CONFIDENTIALITY STATEMENT</h6>
              <p className="about-text">
                At Reptileindia.co.in, we place a major emphasis on maintaining
                confidentiality of Client/User information and Information
                Security Management. The following are key features of our
                Confidentiality Agreement, applicable to all our clients/users.
              </p>
              <p className="about-text">
                {" "}
                • We do not disclose any information pertaining to our Clients
                or to any third parties.
              </p>
              <p className="about-text">
                • We do not use our Clients identity or Clients brand for our
                marketing purposes, without their written consent.
              </p>
              <p className="about-text">
                • We never provide our Client's name or Client's information to
                other prospective clients for marketing purposes.
              </p>
              <p className="about-text">
                • We do not sell or disclose our Client list or Client
                Information to third parties.
              </p>
              <h6> 11. CONFIDENTIALITY AGREEMENT</h6>
              <p className="about-text">
                {" "}
                It is understood and agreed to that our Client may provide
                certain information that is and must be kept confidential. To
                ensure the protection of such information, and to preserve any
                confidentiality necessary under patent and/or trade secret laws,
                it is agreed that:{" "}
              </p>
              <p className="about-text">
                The Confidential Information to be disclosed can be described as
                and includes Invention description(s), technical and business
                information relating to proprietary ideas and inventions, ideas,
                patentable ideas, trade secrets, drawings and/or illustrations,
                patent searches, existing and/or contemplated products and
                services, research and development, production, costs, profit
                and margin information, finances and financial projections,
                customers, clients, marketing, and current or future business
                plans and models, regardless of whether such information is
                designated as "Confidential Information" at the time of its
                disclosure. Reptileindia.co.in shall limit disclosure of
                Confidential Information within its own organization to its
                directors, officers, partners, members, employees and/or
                independent contractors (collectively referred to as
                "affiliates") having a need to know. Reptileindia.co.in and
                affiliates will not disclose the confidential information
                obtained from the discloser unless required to do so by law.
              </p>
              <p className="about-text">
                This Agreement imposes no obligation upon Reptileindia.co.in
                with respect to any Confidential Information{" "}
              </p>
              <p className="about-text">
                (a) that was in Reptileindia.co.in possession before receipt
                from Client;{" "}
              </p>
              <p className="about-text">
                (b) is or becomes a matter of public knowledge through no fault
                of Reptileindia.co.in;{" "}
              </p>
              <p className="about-text">
                (c) is rightfully received by Reptileindia.co.in from a third
                party not owing a duty of confidentiality to the
                Reptileindia.co.in’s Client;
              </p>
              <p className="about-text">
                (d) is disclosed without a duty of confidentiality to a third
                party by, or with the authorization of Reptileindia.co.in;{" "}
              </p>
              <p className="about-text">
                (e) is public knowledge or the information is available in
                public domain; or{" "}
              </p>
              <p className="about-text">
                (f) is independently derived by Reptileindia.co.in.
              </p>
              This Agreement states the entire agreement between
              Reptileindia.co.in and its Client concerning the disclosure of
              Confidential Information. Any addition or modification to this
              Agreement must be made in writing and signed by the parties.
              <p />
              <p className="about-text">
                If any of the provisions of this Agreement are found to be
                unenforceable, the remainder shall be enforced as fully as
                possible and the unenforceable provision(s) shall be deemed
                modified to the limited extent required to permit enforcement of
                the Agreement as a whole. You are solely responsible any
                consequences, losses, or damages that we may directly or
                indirectly incur or suffer due to any unauthorized activities
                conducted by you, as explained above, and may incur criminal or
                civil liability.
              </p>
              <h6>12. CONTACT US</h6>
              <p className="about-text">
                For any query contact us at REPTILEINDIA.CO.IN
              </p>
              <p className="about-text">
                Carecone Technologies Private Limited{" "}
              </p>
              <p className="about-text">
                Feel free to write us at info@reptileindia.co.in ,we have a
                designated person to answer all your queries.
              </p>
              <div className="cta-area">
              <Link to="/contact-us">
                <a
                  className=" btn-solid reveal-start"
                 // href="https://www.reptileindia.co.in/contact-us"
                >
                  Contact Us
                </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
     <div className="container">
      <h1>Privacy Policy for Reptile - AntiTheft</h1>
      <p>Last Updated: 2024-09-10</p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to Reptile - AntiTheft. Your privacy and security are of utmost importance to us. This Privacy Policy explains how we collect, use, disclose, and protect your information, including for users who are children. By using our app, you consent to the practices described in this policy.
      </p>

      <h2>2. Information We Collect</h2>

      <h3>2.1 Personal Information</h3>
      <p>
        We collect certain types of information to provide you with our anti-theft features and ensure a secure experience. For users under the age of 13, we are committed to handling personal information with extra care and obtaining parental consent where required.
      </p>
      <p><strong>Registration Information:</strong> When setting up the app, users may provide personal information such as their name and age. We collect this information only with parental consent for children under 13.</p>
      <p><strong>Profile Information:</strong> Users can create a profile within the app, which may include additional details such as preferences and settings. This information is collected with parental consent for children under 13.</p>

      <h3>2.2 Device and Usage Information</h3>
      <p><strong>Device Details:</strong> We collect information about your device, including model, operating system version, and unique identifiers (e.g., IMEI, UDID).</p>
      <p><strong>Network Information:</strong> We gather data about your network connection, such as IP address and connection type, to enhance functionality and security.</p>
      <p><strong>App Interactions:</strong> We track how the app is used, including interactions with features and time spent in different sections, to improve performance and user experience.</p>
      <p><strong>Diagnostic Data:</strong> We collect error logs and crash reports to diagnose and resolve technical issues.</p>

      <h3>2.3 Location and Media Data</h3>
      <p><strong>Location Data:</strong> If you enable location-based features, we collect real-time location data. This includes data related to battery overcharge detection, charging disconnect detection, and other location-based services.</p>
      <p><strong>Media Access:</strong> We request permission to access media files (e.g., photos) if necessary for app features such as alert notifications and media management.</p>

      <h3>2.4 Permissions Required</h3>
      <p>Our app requires various permissions to function effectively, and these are granted only with user consent. The permissions include:</p>
      <ul>
        <li><strong>Device Permissions:</strong> Access to device features such as camera, microphone, and storage (e.g., android.permission.CAMERA, android.permission.RECORD_AUDIO, android.permission.READ_EXTERNAL_STORAGE, android.permission.WRITE_EXTERNAL_STORAGE).</li>
        <li><strong>Location Permissions:</strong> Access to location data, including precise and coarse location (e.g., android.permission.ACCESS_FINE_LOCATION, android.permission.ACCESS_COARSE_LOCATION).</li>
        <li><strong>Special Permissions:</strong> Permissions for specific functionalities, such as foreground services (android.permission.FOREGROUND_SERVICE, android.permission.FOREGROUND_SERVICE_LOCATION, android.permission.FOREGROUND_SERVICE_MICROPHONE, etc.), and device admin (android.permission.BIND_DEVICE_ADMIN).</li>
      </ul>

      <h2>3. How We Use Your Information</h2>

      <h3>3.1 Providing and Operating the App</h3>
      <p><strong>Feature Functionality:</strong> We use data to enable features such as battery overcharge detection, face detection, motion detection, and more. This helps in delivering the core functionalities of our anti-theft services.</p>
      <p><strong>Performance:</strong> Data helps us monitor and improve app performance and reliability.</p>

      <h3>3.2 Personalization and Improvement</h3>
      <p><strong>Customization:</strong> Information is used to tailor the app experience, including personalized alerts and notifications.</p>
      <p><strong>Feedback and Updates:</strong> We analyze user feedback and usage data to enhance features and add new functionalities.</p>

      <h3>3.3 Communication</h3>
      <p><strong>Notifications:</strong> We send notifications about app updates, features, and security alerts. Parents can manage notification settings.</p>
      <p><strong>Support:</strong> We use information to provide support and resolve issues.</p>

      <h3>3.4 Security and Protection</h3>
      <p><strong>Fraud Prevention:</strong> Data helps us detect and prevent fraud and misuse of the app.</p>
      <p><strong>Compliance:</strong> We use information to comply with legal obligations and protect our rights.</p>

      <h2>4. How We Share Your Information</h2>

      <h3>4.1 Service Providers</h3>
      <p><strong>Third-Party Services:</strong> We may share information with trusted partners who assist in app operations, such as cloud services and support providers.</p>

      <h3>4.2 Legal Requirements</h3>
      <p><strong>Legal Obligations:</strong> Information may be disclosed if required by law, regulation, or legal process.</p>
      <p><strong>Enforcement:</strong> We may share information to enforce our terms of service and protect against legal claims.</p>

      <h3>4.3 Business Transactions</h3>
      <p><strong>Mergers and Acquisitions:</strong> In the event of a business transfer, such as a merger or acquisition, information may be transferred as part of the transaction.</p>

      <h2>5. Security of Your Information</h2>

      <h3>5.1 Technical Safeguards</h3>
      <p><strong>Encryption:</strong> We use encryption to secure data during transmission.</p>
      <p><strong>Access Controls:</strong> Access to data is restricted to authorized personnel, and authentication mechanisms are in place.</p>

      <h3>5.2 Organizational Measures</h3>
      <p><strong>Data Protection:</strong> We have policies to safeguard information and regularly assess our security practices.</p>
      <p><strong>Incident Response:</strong> We have procedures to address and manage security incidents.</p>

      <h2>6. Parental Consent and Control</h2>

      <h3>6.1 Parental Consent</h3>
      <p><strong>Obtaining Consent:</strong> We obtain parental consent before collecting personal information from children under 13.</p>
      <p><strong>Parental Rights:</strong> Parents can review, update, or delete their child’s information and manage consent through the app or by contacting us.</p>

      <h3>6.2 Account Management</h3>
      <p><strong>Parental Controls:</strong> Parents can adjust settings and permissions related to their child’s account.</p>
      <p><strong>Contact Us:</strong> For any privacy-related questions or concerns, parents can contact us using the details provided below.</p>

      <h2>7. Your Choices and Rights</h2>

      <h3>7.1 Access and Correction</h3>
      <p><strong>View and Update:</strong> Users can access and update their personal information through app settings.</p>
      <p><strong>Account Deletion:</strong> Users can request deletion of their account and data, subject to legal requirements.</p>

      <h3>7.2 Permissions</h3>
      <p><strong>Device Permissions:</strong> Users can manage permissions through device settings.</p>
      <p><strong>Notifications:</strong> Users can adjust notification preferences within the app.</p>

      <h3>7.3 Data Portability</h3>
      <p><strong>Export Data:</strong> Users can request a copy of their data for transfer.</p>

      <h3>7.4 Opt-Out</h3>
      <p><strong>Marketing Communications:</strong> Users can opt out of marketing communications as specified in the app.</p>

      <h2>8. Children’s Privacy</h2>

      <h3>8.1 Compliance with COPPA</h3>
      <p>Reptile - AntiTheft is committed to protecting the privacy of children:</p>
      <p><strong>Data Collection:</strong> We comply with the Children’s Online Privacy Protection Act (COPPA) and similar regulations, ensuring we do not collect personal information from children under 13 without parental consent.</p>
      <p><strong>Parental Involvement:</strong> Parents can review and manage their child’s information and data collection.</p>

      <h2>9. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated "Last Updated" date. We encourage you to review the policy periodically.</p>

      <h2>10. Contact Us</h2>
      <p>For questions or concerns about this Privacy Policy, please contact us:</p>
      <div className="contact-info">
        <p>Email: nidhi@reptileindia.co.in</p>
        <p>Address: 265, 2nd floor, Aggarwal City Plaza, Mangalam Place, Rohini, New Delhi, Delhi, 110085
        </p>
        <p>Phone: +91 9311572747 , +91 8178334747</p>
      </div>

      <h2>11. Data Protection Officer (DPO)</h2>
      <p>If applicable, you may contact our Data Protection Officer for privacy-related queries:</p>
      <div className="contact-info">
        <p>Name: Manoj Kumar</p>
        <p>Email: manoj.kumar@reptileindia.co.in</p>
        <p>Phone: +919212748200</p>
      </div>

      <h2>12. International Users</h2>
      <p>If you access the app from outside [Your Country], your information may be transferred to and processed in [Your Country]. By using the app, you consent to the transfer and processing of your information.</p>

      <h2>13. Third-Party Links</h2>
      <p>The app may contain links to third-party websites. We are not responsible for the privacy practices or content of these third parties. We encourage you to review their privacy policies.</p>

      <h2>14. Cookies and Tracking Technologies</h2>
      <p>We use cookies and tracking technologies to enhance user experience and analyze app usage. Users can manage cookie preferences through browser settings.</p>

      <h2>15. User Rights Under GDPR (If Applicable)</h2>
      <p>For residents of the European Union (EU), you have rights under the General Data Protection Regulation (GDPR):</p>
      <ul>
        <li><strong>Right to Access:</strong> Request access to your personal data.</li>
        <li><strong>Right to Rectification:</strong> Request correction of inaccurate data.</li>
        <li><strong>Right to Erasure:</strong> Request deletion of data under certain conditions.</li>
        <li><strong>Right to Restrict Processing:</strong> Request restriction of data processing.</li>
        <li><strong>Right to Data Portability:</strong> Request data in a structured, commonly used format.</li>
        <li><strong>Right to Object:</strong> Object to processing based on legitimate interests or direct marketing.</li>
      </ul>
      <p>To exercise these rights, contact us at the details provided above.</p>

      <h2>16. California Privacy Rights (If Applicable)</h2>
      <p>For California residents, the California Consumer Privacy Act (CCPA) provides specific rights:</p>
      <ul>
        <li><strong>Right to Know:</strong> Request information about data collection, use, and sharing.</li>
        <li><strong>Right to Delete:</strong> Request deletion of personal data.</li>
        <li><strong>Right to Opt-Out:</strong> Opt out of data sales (note: we do not sell personal data).</li>
        <li><strong>Non-Discrimination:</strong> We will not discriminate for exercising privacy rights.</li>
      </ul>
      <p>To exercise these rights, contact us using the information provided above.</p>
    </div>
  </section>
</>

        <Footer />
        </>
    </Fragment>
  )
}
export default PrivacyPolicymobilesecurity;